<template>
    <div>
        <div>
            <el-table
                    :data="tableData"
                    border
                    size="small"
                    stripe
                    style="width: 100%">
                <el-table-column
                        prop="created_at"
                        label="添加时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        prop="products.name"
                        label="补充包"
                >
                </el-table-column>
                <el-table-column
                        prop="card_name"
                        label="卡牌名称"
                >
                </el-table-column>
                <el-table-column
                        prop="label"
                        label="卡牌标签"
                >
                </el-table-column>
                <el-table-column
                        label="卡牌图片"
                        width="150"

                >
                    <template slot-scope="scope">
                        <el-image
                                style="height: 40px;"
                                :src="scope.row.img"
                                fit="contain"
                                :preview-src-list="[scope.row.img]"
                        >
                        </el-image>
                    </template>
                </el-table-column>
                <el-table-column width="180" label="操作">
                    <template slot-scope="scope">

                        <el-button
                                size="mini"
                                type="danger"
                                @click="handleSelect(scope.row)"
                        >
                            选择
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "select-card",
        props: {
            category: {
                type: Number,
                default: 0
            },
        },
        data() {
            return {
                tableData: []
            }
        },
        methods: {
            handleSelect(row) {
                console.log(row)
                this.$emit("selectCard",row)
            },
            getList:function () {
                let page = 1;
                let limit = 999;
                let category = this.category
                this.$axios.post("site/siteDayCards/getList",{page,limit,category})
                    .then((res) => {
                        if (res.data.code == 1000){
                            this.tableData = res.data.data.list;
                        }
                    })
            },
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>

</style>
