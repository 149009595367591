<template>
    <div>
        <div>
            <Breadcrumb content="每日一卡设置"/>
        </div>
        <div class="m-bg m-pd" >
            <el-form v-model="form" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="补充包" required>
                            <el-select class="m-full" v-model="form.product_id">
                                <el-option v-for="(v,k) in products" :label="v.name" :key="k" :value="v.id"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="8">
                        <el-form-item label="标题" required>
                            <el-input v-model="form.title"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8" v-if="form.product_id">
                        <el-form-item  >
                            <el-button @click="dialogVisible=true">选择卡牌</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18">
                        <el-form-item>
                            <div>
                                <el-table
                                        :data="selectData"
                                        border
                                        size="small"
                                        stripe
                                        style="width: 100%">
                                    <el-table-column
                                            prop="created_at"
                                            label="添加时间"
                                            width="180">
                                    </el-table-column>
                                    <el-table-column
                                            prop="products.name"
                                            label="补充包"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="card_name"
                                            label="卡牌名称"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="label"
                                            label="卡牌标签"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            label="卡牌图片"
                                            width="150"

                                    >
                                        <template slot-scope="scope">
                                            <el-image
                                                    style="height: 40px;"
                                                    :src="scope.row.img"
                                                    fit="contain"
                                                    :preview-src-list="[scope.row.img]"
                                            >
                                            </el-image>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="180" label="操作">
                                        <template slot-scope="scope">

                                            <el-button
                                                    size="mini"
                                                    type="danger"
                                                    @click="delSelectCard(scope.row)"
                                            >
                                                删除
                                            </el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="8">
                        <el-form-item  >
                            <el-button type="success" @click="submit">立即保存</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <!--选择卡-->

        <el-dialog title="选择卡牌" :visible.sync="dialogVisible">
            <SelectCard
                    v-if="dialogVisible"
                    :category="form.product_id"
                    @selectCard="revSelectCard"
            >
            </SelectCard>
        </el-dialog>
    </div>
</template>

<script>
    import Breadcrumb from '@/components/admin/breadcrumb'
    import SelectCard from './components/select-card'
    export default {
        name: "card-set",
        components:{Breadcrumb,SelectCard},
        data() {
            return {
                form: {
                    product_id:"",
                    title:"",
                    card_ids:"",
                },
                products:[],
                selectData:[],
                dialogVisible: false
            }
        },
        methods: {
            getProductList(){
                this.$axios.post("site/siteProducts/getSelectList")
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.products = res.data.data;
                            console.log(this.products)
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            },
            revSelectCard(obj){
                this.dialogVisible = false;
                for (let i = 0;i<this.selectData.length;i++){
                    if (this.selectData[i].id == obj.id){
                        this.$message.error("已存在相同卡牌");
                        return ;
                    }
                }
                this.selectData.push(obj)
            },
            delSelectCard(obj){
                for (let i = 0;i<this.selectData.length;i++){
                    if (this.selectData[i].id == obj.id){
                        this.selectData.splice(i,1);
                        return ;
                    }
                }
            },
            submit() {
                this.form.card_ids = "";
                for (let i = 0;i<this.selectData.length;i++){
                    this.form.card_ids += this.selectData[i].id+","
                }
                this.$axios.post("site/siteDayCards/setDayCards",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.$message.success("保存成功");

                        }else{
                            this.$message.error(res.data.msg)
                        }
                })
            },
            getData(){
                this.$axios.post("site/siteDayCards/getDayCards",this.form)
                    .then((res)=>{
                        if (res.data.code == 1000){
                            this.form = res.data.data.setData;
                            this.selectData = res.data.data.selectData;
                        }else{
                            this.$message.error(res.data.msg)
                        }
                    })
            }
        },
        created() {
            this.getProductList();
            this.getData();
        }
    }
</script>

<style scoped>

</style>
